import React from 'react'
import { Modal, Input } from '@duckma/react-ds'
import styled from 'styled-components'

import { LocalizedButton } from '../../../components/Button'
import { translate } from '../../../i18n/dashboard_labels'

export const PersonalisationModal: React.FC<{
  open: boolean
  customizationNotes?: string
  editing: boolean
  onChange: (data: string) => void
  onClose: () => void
  onRemove: () => void
  onContinue: () => void
}> = ({ open, customizationNotes, editing, onChange, onRemove, onContinue, onClose }) => {
  return (
    <StyledModal
      open={open}
      onClose={onClose}
      title={translate('personalization_notes')}
      color="white"
    >
      <Content>
        <StyledTextArea
          name={translate('personalization_notes')}
          placeholder={translate('personalization_notes_placeholder')}
          columns={100}
          onChange={onChange}
          value={customizationNotes}
        />
        <ButtonPanel>
          {editing && (
            <LocalizedButton
              text={'remove'}
              outline
              color="danger"
              size="small"
              onClick={() => {
                onRemove()
              }}
              radius={50}
            />
          )}
          <LocalizedButton
            text={editing ? 'save' : 'select'}
            size="small"
            onClick={() => {
              onContinue()
            }}
            radius={50}
          />
        </ButtonPanel>
      </Content>
    </StyledModal>
  )
}

const StyledTextArea = styled(Input)`
  font-family: 'Open Sans', sans-serif;
  padding: 8px;
  > textarea {
    height: 15vh;
    resize: none;
  }
  width: calc(100% - 16px);
`

const ButtonPanel = styled.div`
  display: flex;
  justify-content: space-evenly;
  > * {
    width: fit-content;
    padding: 12px 32px;
  }
`

const Content = styled.div`
  padding: 0 10% 5% 10%;
  display: flex;
  min-height: calc(95% - 64px);
  /* height: 60%; */
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
`

const StyledModal = styled(Modal)`
  position: absolute;
  left: 35vw;
  right: 35vw;
  top: 28vh;
  bottom: 28vh;
  outline: none;
  border-radius: 20px;
  @media screen and (max-width: 800px) {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 0;
  }
  > * {
    z-index: 2;
  }
`

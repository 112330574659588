import { version } from '../../package.json'

export type Languages = 'it' | 'en'

export const translate = (key: string, ...replaces: (string | number)[]): string => {
  if (!key) return ''
  if (!dashboardLabels[key]) {
    console.warn('[Translate] Could not find translation for key: ', key)
    console.warn(`Can't translate ${key}`)
    return key
  }
  let lang: Languages = 'en'
  if (navigator.language === 'it' || navigator.language === 'it-IT') lang = 'it'
  let translation = dashboardLabels[key][lang] || key
  if (replaces && replaces.length) {
    translation = replaces
      .map((r) => String(r))
      .reduce((pre, curr, index) => pre.replace(`$${index + 1}`, curr), translation)
  }
  return translation
}

export const dashboardLabels: { [key: string]: { [key in Languages]: string } } = {
  logout: {
    it: 'Logout',
    en: 'Logout',
  },
  access: {
    it: 'Accedi',
    en: 'Access',
  },
  login: {
    it: 'Accedi',
    en: 'Login',
  },
  signup: {
    it: 'Registrati',
    en: 'Signup',
  },
  edit_button_tooltip: {
    it: 'Modifica',
    en: 'Edit',
  },
  remove_button_tooltip: {
    it: 'Rimuovi',
    en: 'Remove',
  },
  mobile: {
    it: 'Mobile (senza console)',
    en: 'Mobile (without console)',
  },
  mobile_console: {
    it: 'Mobile (con console)',
    en: 'Mobile (with console)',
  },
  web_mobile: {
    it: 'Web + Mobile',
    en: 'Web + Mobile',
  },
  web: {
    it: 'Web (PWA senza console)',
    en: 'Web (PWA without console)',
  },
  web_console: {
    it: 'Web (PWA con console)',
    en: 'Web (PWA with console)',
  },
  enter_button: {
    it: 'Entra →',
    en: 'Login →',
  },
  additional_services: {
    it: 'Servizi aggiuntivi',
    en: 'Additional services',
  },
  services_label: {
    it: 'Servizi:',
    en: 'Services:',
  },
  components_labe: {
    it: 'Componenti:',
    en: 'Components:',
  },
  project_setup_label: {
    it: 'Setup progetto:',
    en: 'Project setup:',
  },
  waiting_for_offer: {
    it: 'Inviata in attesa di proposta',
    en: 'Sent, waiting for offer',
  },
  send: {
    it: 'Invia',
    en: 'Send'
  },
  save: {
    it: 'Salva',
    en: 'Save',
  },
  save_tooltip: {
    it: `Salva le modifiche senza richiedere un'offerta`,
    en: 'Save without requesting quotation',
  },
  already_requested_tooltip: {
    it: `È già stata richiesta un'offerta per questa configurazione`,
    en: 'Quotation already requested for this configuration',
  },
  cant_save_tooltip: {
    it: `Non è possibile aggiornare una configurazione per cui è già stata richiesta un'offerta`,
    en: `You can't update a configuration already submitted for quotation`,
  },
  cant_modify_tooltip: {
    it: `Non è possibile modificare una configurazione per cui è già stata richiesta un'offerta`,
    en: `You can't edit a configuration already submitted for quotation`,
  },
  request_tooltip: {
    it: `Non sarà più possibile modificare la configurazione dopo aver richiesto l'offerta`,
    en: `You won't be able to edit the configuration after requestiong quotation`,
  },
  prospect_saved: {
    it: 'Salvata, non inviata',
    en: 'Saved, not sent',
  },
  offer_received: {
    it: 'Proposta ricevuta',
    en: 'Offer received',
  },
  service: {
    it: 'Servizio',
    en: 'Service',
  },
  services: {
    it: 'Servizi',
    en: 'Services',
  },
  summary_arrow: {
    it: 'Riepilogo →',
    en: 'Summary →',
  },
  personalization_notes: {
    it: 'Note di personalizzazione',
    en: 'Personalization notes',
  },
  select_one_or_more_additional_services: {
    it: 'Seleziona uno o più servizi aggiuntivi',
    en: 'Select one or more additional services',
  },
  select_desired_platforms: {
    it: `Seleziona la piattaforma per cui si desidera realizzare l'app`,
    en: 'Pick a platform for your application',
  },
  select_desired_graphics: {
    it: `Seleziona il tipo di grafica per la tua applicazione`,
    en: 'Pick a graphic type for your application',
  },
  no_account_question: {
    it: 'Non hai un account?',
    en: 'No account?',
  },
  profile: {
    it: 'Profilo',
    en: 'Profile',
  },
  architecture_studio: {
    it: 'Studio di Architettura',
    en: 'Architecture Studio',
  },
  project_setup: {
    it: 'Setup di progetto',
    en: 'Project setup',
  },
  components_value: {
    it: 'Valore Componenti',
    en: 'Components Value',
  },
  furniture_maker: {
    it: 'Mobiliere',
    en: 'Furniture Maker',
  },
  furniture_showroom: {
    it: 'Showroom di Arredo',
    en: 'Furniture Showroom',
  },
  interior_designer: {
    it: 'Interior Designer',
    en: 'Interior Designer',
  },
  linen_store: {
    it: 'Negozio di Biancheria',
    en: 'Linen Store',
  },
  textile_showroom: {
    it: 'Tappezziere',
    en: 'Textile Showroom',
  },
  short_description_label: {
    it: 'Descrivi brevemente la tua app.',
    en: 'Give a short description of your app.',
  },
  short_description_title: {
    it: 'Descrizione breve',
    en: 'Short description',
  },
  other: {
    it: 'Altro',
    en: 'Other',
  },
  user_data: {
    it: 'Dati utente',
    en: 'User data',
  },
  configuration_history: {
    it: 'Storico configurazioni',
    en: 'Configuration history',
  },
  search_prompt: {
    it: 'Inserisci il testo da cercare',
    en: 'Insert search text',
  },
  select_an_element: {
    it: 'Seleziona un elemento',
    en: 'Select an element',
  },
  no_item_found: {
    it: 'Nessun elemento trovato',
    en: 'No item found',
  },
  search: {
    it: 'Cerca',
    en: 'Search',
  },
  loading: {
    it: 'Caricamento...',
    en: 'Loading...',
  },
  at_least_8_characters: {
    it: 'Almeno 8 caratteri',
    en: 'At least 8 characters',
  },
  uppercase_and_lowercase: {
    it: 'Sia maiuscole che minuscole',
    en: 'Upper and lower case letters',
  },
  at_least_a_number: {
    it: 'Almeno un numero',
    en: 'At least a number',
  },
  forgot_password_question: {
    it: 'Password dimenticata?',
    en: 'Forgot password?',
  },
  insert_password: {
    it: 'Inserisci password',
    en: 'Insert password',
  },
  recover_password_subtitle: {
    it: 'Recupera password',
    en: 'Password recovery',
  },
  insert_code: {
    it: 'Inserisci codice',
    en: 'Insert code',
  },
  update_password_button: {
    it: 'Modifica password',
    en: 'Update password',
  },
  back_to_login: {
    it: 'Torna al login',
    en: 'Back to login',
  },
  insert_email_placeholder: {
    it: 'Inserisci indirizzo email',
    en: 'Insert email address',
  },
  phone_number: {
    it: 'Numero di telefono',
    en: 'Phone number',
  },
  attachment_label: {
    it: 'Allegato',
    en: 'Attachment',
  },
  file_name_tooltip: {
    it: 'Allegato: $1',
    en: 'Attachment: $1',
  },
  show_attachment: {
    it: 'Vedi allegato',
    en: 'See attachment',
  },
  add_attachment: {
    it: 'Aggiungi allegato',
    en: 'Add attachment',
  },
  edit_attachment: {
    it: 'Modifica allegato',
    en: 'Edit attachment',
  },
  see_offer_document: {
    it: 'Visualizza offerta',
    en: 'Display offer',
  },
  see_offer_document_tooltip: {
    it: `Visualizza il PDF dell'offerta`,
    en: 'Display the offer PDF',
  },
  attachment_tooltip: {
    it: `Scarica l'offerta allegata`,
    en: `Download the offer document`,
  },
  insert_phone_placeholder: {
    it: 'Inserisci numero di telefono',
    en: 'Insert phone number',
  },
  insert_password_placeholder: {
    it: 'Inserisci password',
    en: 'Insert password',
  },
  insert_new_password_placeholder: {
    it: 'Inserisci nuova password',
    en: 'Insert new password',
  },
  insert_website_placeholder: {
    it: 'Inserisci sito web',
    en: 'Insert web site',
  },
  insert_business_type_placeholder: {
    it: 'Scegli tipo azienda',
    en: 'Pick business type',
  },
  insert_agent_type_placeholder: {
    it: 'Scegli tipo agente',
    en: 'Pick agent type',
  },
  insert_phone_number_placeholder: {
    it: 'Inserisci telefono',
    en: 'Insert phone number',
  },
  insert_country_placeholder: {
    it: 'Inserisci nazione',
    en: 'Insert country',
  },
  insert_city_placeholder: {
    it: 'Inserisci città',
    en: 'Insert city',
  },
  insert_zip_code_placeholder: {
    it: 'Inserisci CAP',
    en: 'Insert ZIP Code',
  },
  insert_address_placeholder: {
    it: 'Inserisci indirizzo',
    en: 'Insert address',
  },
  insert_business_name_placeholder: {
    it: 'Inserisci nome azienda',
    en: 'Insert business name',
  },
  insert_last_name_placeholder: {
    it: 'Inserisci cognome',
    en: 'Insert last name',
  },
  insert_first_name_placeholder: {
    it: 'Inserisci nome',
    en: 'Insert first name',
  },
  insert_appellation_placeholder: {
    it: 'Inserisci titolo',
    en: 'Insert appellation',
  },
  insert_backoffice_operator_placeholder: {
    it: 'Inserisci operatore backoffice',
    en: 'Insert backoffice operator',
  },
  privacy_accept_text: {
    it: `Accetto l'informativa per il trattamento dei dati personali`,
    en: 'I acconsent to the Privacy Statement',
  },
  marketing_accept_text: {
    it: `Acconsento all'invio di comunicazioni commerciali e di marketing`,
    en: 'I acconsent to marketing communications',
  },
  mailing_list_accept_text: {
    it: `Iscrizione alla newsletter`,
    en: 'Subscribe to newsletter',
  },
  continue: {
    it: 'Procedi',
    en: 'Continue',
  },
  form_prompt: {
    it: `Inserisci i dati richiesti nel form qui sotto per ricevere una mail riepilogativa delle funzionalità che hai selezionato all'interno del nostro Configuratore.\nNella stessa email avrai anche la possibilità di prenotare una call da remoto (Meet) con un nostro esperto che ti dedicherà 30 minuti fornendoti suggerimenti e preziose valutazioni per il tuo progetto. Mettiamo a disposizione gratuitamente 6 call a settimana, affrettati e prenota la tua consulenza gratuita.`,
    en: `Enter the required data in the form below to receive an email summarizing the features you have selected in our Configurator.\nIn the same email you will also have the opportunity to book a remote call (Meet) with one of our experts who will dedicate 30 minutes to you, providing you with suggestions and valuable evaluations for your project. We provide 6 calls a week for free, hurry up and book your free consultation.`
  },
  you_will_receive_a_recovery_code: {
    it: 'Ti verrà inviato via mail il codice per recuperare la password',
    en: 'You will receive a code via mail to recover your password',
  },
  required_field: {
    it: 'Campo obbligatorio',
    en: 'Required field',
  },
  wait_for_activation_message: {
    it: 'Attendi l’associazione ad un venditore per poter accedere al configuratore',
    en: 'Wait for seller association in order to access the configurator',
  },
  save_changes: {
    it: 'Salva modifiche',
    en: 'Save changes',
  },
  agent: {
    it: 'Agente',
    en: 'Agent',
  },
  distributor: {
    it: 'Distributore',
    en: 'Distributor',
  },
  version: {
    it: `versione: ${version}`,
    en: `version: ${version}`,
  },
  back_button_arrow: {
    it: '← Indietro',
    en: '← Back',
  },
  back: {
    it: 'Indietro',
    en: 'Back',
  },
  modify: {
    it: 'Modifica',
    en: 'Modify',
  },
  modify_tooltip: {
    it: 'Modifica configurazione',
    en: 'Modify configuration',
  },
  back_home: {
    it: 'Torna alla home',
    en: 'Back home',
  },
  next: {
    it: 'Avanti',
    en: 'Next',
  },
  next_arrow: {
    it: 'Avanti →',
    en: 'Next →',
  },
  welcome: {
    it: 'Benvenuto',
    en: 'Welcome',
  },
  start_configuration: {
    it: 'inizia una nuova configurazione',
    en: 'start a new configuration',
  },
  title_label: {
    it: 'Dai un titolo alla tua app',
    en: 'Give a title to your app',
  },
  title_title: {
    it: 'Titolo',
    en: 'Title',
  },
  reference_label: {
    it: 'Riferimento progetto',
    en: 'Project reference',
  },
  description_label: {
    it: 'Descrizione',
    en: 'Description',
  },
  go_to_summary: {
    it: 'Vai al riepilogo',
    en: 'Go to summary',
  },
  base_components: {
    it: 'Componenti Base',
    en: 'Base Components',
  },
  components_section_title: {
    it: 'Moduli',
    en: 'Modules',
  },
  components_section_subtitle: {
    it: 'Seleziona i moduli che vuoi includere nella tua app',
    en: 'Select the modules you wish to include in your app',
  },
  pesonalized_components: {
    it: 'Componenti personalizzati',
    en: 'Personalized components',
  },
  custom_price: {
    it: 'a parte',
    en: 'aside',
  },
  simple_price: {
    it: '$1 €',
    en: '$1 €',
  },
  monthly_price: {
    it: '$1 € / mese',
    en: '$1 € / month',
  },
  simple_total_price: {
    it: 'Prezzo totale: a partire da $1 €',
    en: 'Total price: starting from $1 €',
  },
  total_price_from: {
    it: 'Prezzo totale: $1 €',
    en: 'Total price: $1 €',
  },
  price_from: {
    it: 'da $1 €',
    en: 'from $1 €',
  },
  price_recurrent_from: {
    it: 'da $1 €/mese',
    en: 'from $1 €/month',
  },
  price_recurrent: {
    it: '$1 €/mese',
    en: '$1 €/month',
  },
  skip_component_button: {
    it: 'No, non lo voglio',
    en: 'No, skip this',
  },
  personalized: {
    it: 'Personalizzata',
    en: 'Personalized',
  },
  basic: {
    it: 'Base',
    en: 'Basic',
  },
  client_provided: {
    it: 'Fornita dal cliente',
    en: 'Provided by client',
  },
  graphics: {
    it: 'Grafiche',
    en: 'Graphics',
  },
  platforms: {
    it: 'Piattaforme',
    en: 'Platforms',
  },
  platform_total: {
    it: 'Totale Piattaforma',
    en: 'Platform Total',
  },
  personalizations: {
    it: 'Personalizzazioni',
    en: 'Personalizations',
  },
  capitalized_total: {
    it: 'INVESTIMENTO',
    en: 'INVESTMENT',
  },
  graphics_total: {
    it: 'Totale Grafiche',
    en: 'Graphics Total',
  },
  capitalized_total_replace: {
    it: '$1 € : INVESTIMENTO ',
    en: '$1 € : INVESTMENT',
  },
  capitalized_total_replace_from: {
    it: 'A PARTIRE DA $1 € : INVESTIMENTO',
    en: 'STARTING FROM $1 € : INVESTMENT',
  },
  setup_replace: {
    it: 'COSTI DI SETUP $1€',
    en: 'SETUP COSTS $1€',
  },
  capitalized_monthly_total_replace: {
    it: '$1 € : INVESTIMENTO MENSILE',
    en: '$1 € : MONTHLY INVESTMENT',
  },
  capitalized_monthly_total_replace_from: {
    it: 'A PARTIRE DA $1 € : INVESTIMENTO MENSILE',
    en: 'STARTING FROM $1 € : MONTHLY INVESTMENT',
  },
  capitalized_monthly_total: {
    it: 'INVESTIMENTO MENSILE',
    en: 'MONTHLY INVESTMENT',
  },
  fixed_total_label: {
    it: 'Investimento',
    en: 'Investment',
  },
  monthly_total_label: {
    it: 'Investimento mensile',
    en: 'Monthly investment',
  },
  confirm_quantity: {
    it: 'Conferma quantità',
    en: 'Confirm quantity',
  },
  add_element: {
    it: 'Aggiungi elemento',
    en: 'Add element',
  },
  base_solution: {
    it: 'Soluzione Base',
    en: 'Base solution',
  },
  base: {
    it: 'Base',
    en: 'Base',
  },
  more_info: {
    it: 'Maggiori informazioni',
    en: 'More information',
  },
  summary: {
    it: 'Riepilogo Configurazione',
    en: 'Summary',
  },
  quantity: {
    it: 'Quantità',
    en: 'Quantity',
  },
  request_quotation: {
    it: 'Richiedi offerta finale',
    en: 'Request final offer',
  },
  update_quotation: {
    it: 'Aggiorna offerta →',
    en: 'Update offer →',
  },
  save_configuration: {
    it: 'Salva configurazione',
    en: 'Save configuration',
  },
  new_configuration: {
    it: 'Nuova configurazione',
    en: 'New configuration',
  },
  last_configurations: {
    it: 'Ultime configurazioni',
    en: 'Last configurations',
  },
  see_history: {
    it: 'Vedi storico',
    en: 'See history',
  },
  configurations: {
    it: 'Configurazioni',
    en: 'Configurations',
  },
  configuration_detail: {
    it: 'Dettagli configurazione',
    en: 'Configuration details',
  },
  clone_configuration: {
    it: 'Clona configurazione',
    en: 'Clone configuration',
  },
  are_you_sure: {
    it: 'Sei sicuro?',
    en: 'Are you sure?',
  },
  confirm: {
    it: 'Conferma',
    en: 'Confirm',
  },
  cancel: {
    it: 'Annulla',
    en: 'Cancel',
  },
  delete: {
    it: 'Elimina',
    en: 'Delete',
  },
  delete_prompt: {
    it: 'Sei sicuro di voler eliminare il componente?',
    en: 'Are you sure you want to delete this component?',
  },
  quotation_prompt: {
    it: 'Sei sicuro di voler richiedere una quotazione?',
    en: 'Are you sure you want to request a quotation?',
  },
  save_prompt: {
    it: 'Sei sicuro di voler salvare questa configurazione?',
    en: 'Are you sure you want to save this configuration?',
  },
  clone_prompt: {
    it: 'Sei sicuro di voler clonare questa configurazione?',
    en: 'Are you sure you want to clone this configuration?',
  },
  no_configurations: {
    it: 'Nessuna configurazione trovata',
    en: 'No configurations found',
  },
  profile_data_modified: {
    it: 'Dati modificati con successo',
    en: 'Data updated successfully',
  },
  password_modified: {
    it: 'Password aggiornata con successo',
    en: 'Password updated successfully',
  },
  configuration_created: {
    it: 'Configurazione salvata con successo',
    en: 'Configuration successfully saved',
  },
  configuration_updated: {
    it: 'Configurazione aggiornata con successo',
    en: 'Configuration successfully updated',
  },
  back_to_configurator: {
    it: 'Configuratore',
    en: 'Configurator',
  },
  print_label: {
    it: 'Stampa',
    en: 'Print',
  },
  print_tooltip: {
    it: 'Stampa riepilogo',
    en: 'Print summary',
  },
  month: {
    it: 'Mese',
    en: 'Month',
  },
  components: {
    it: 'Componenti',
    en: 'Components',
  },
  initial_investment: {
    it: 'Investimento Iniziale',
    en: 'Initial Investment',
  },
  monthly_investment: {
    it: 'Investimento Mensile',
    en: 'Monthly Investment',
  },
  attachments_count: {
    it: '$1 allegati',
    en: '$1 attachments',
  },
  personalization_notes_placeholder: {
    it: 'descrivi brevemente cosa vuoi aggiungere a questo modulo',
    en: 'shortly describe what you want to add',
  },
  select_item_first: {
    it: 'Scegli almeno una voce per continuare',
    en: 'Select at least one item to continue',
  },
  insert_title_first: {
    it: 'Inserisci il titolo per continuare',
    en: 'Insert the title to continue',
  },
  financial_plan: {
    it: 'Piano di investimenti',
    en: 'Financial plan',
  },
  request_financial_plan: {
    it: 'Richiedi piano finanziamenti',
    en: 'Request financial plan',
  },
  percentage_upfront: {
    it: 'Percentuale anticipata',
    en: 'Upfront percentage',
  },
  duration_monthly: {
    it: 'Mesi di finanziamento',
    en: 'Financing months',
  },
  '50_now_50_deploy': {
    it: '50% subito, 50% alla consegna',
    en: '50% before, 50% on deploy',
  },
  first_months_replace: {
    it: 'Primi $1 mesi',
    en: 'First $1 months',
  },
  after: {
    it: 'Poi',
    en: 'After',
  },
  total_prices: {
    it: 'Totale',
    en: 'Total',
  },
  months: {
    it: 'mesi',
    en: 'months',
  },
  status_replace: {
    it: 'Stato: $1',
    en: 'Status: $1',
  },
  accepted: {
    it: 'Accettato',
    en: 'Accepted',
  },
  declined: {
    it: 'Rifiutato',
    en: 'Declined',
  },
  duckmas_comment: {
    it: 'commento-agente',
    en: 'agent-comment',
  },
  attachments: {
    it: 'Allegati',
    en: 'Attachments',
  },
  base_button: {
    it: 'Aggiungi',
    en: 'Add',
  },
  personalize_button: {
    it: 'Personalizza',
    en: 'Personalize',
  },
  request_your_configurator: {
    it: 'Vuoi un configuratore simile a questo? Visita: ',
    en: 'Needing a configurator like this? Visit: ',
  },
  click_here: {
    it: 'Clicca qui!',
    en: 'Click here!',
  },
  see_all: {
    it: 'Vedi tutte',
    en: 'See all',
  },
  edit: {
    it: 'Modifica',
    en: 'Edit',
  },
  remove: {
    it: 'Rimuovi',
    en: 'Remove',
  },
  select: {
    it: 'Seleziona',
    en: 'Select',
  },
  company_name: {
    it: 'Nome Azienda',
    en: 'Company Name',
  },
  customer_name: {
    it: 'Nome Cliente',
    en: 'Customer Name',
  },
  log_in_with_google: {
    it: 'Login con Google',
    en: 'Log in with Google',
  },
  log_in_with_email: {
    it: 'Login con email',
    en: 'Log in with email',
  },
  register_with_email: {
    it: 'Registrati con email',
    en: 'Register with email',
  },
  register: {
    it: 'Registrati',
    en: 'Signup',
  },
  register_text: {
    it:
      "Per salvare l'attuale configurazione è necessario registrarsi (o effettuare il login nel caso ti fossi già registrato).",
    en: 'To save your configuration, you need to sign up (or log in if you already did that).',
  },
  email: {
    it: 'Email',
    en: 'Email',
  },
  name: {
    it: 'Nome',
    en: 'Name',
  },
  last_name: {
    it: 'Cognome',
    en: 'Last Name',
  },
  business_name: {
    it: 'Nome Azienda',
    en: 'Business Name',
  },
  invalid_email: {
    it: 'Email non valida',
    en: 'Invalid email',
  },
  to_be_confirmed: {
    it: 'Valore indicativo da confermare',
    en: 'Value to be confirmed',
  },
  vat_excluded: {
    it: 'Prezzo IVA esclusa',
    en: 'VAT excluded',
  },
  password: {
    it: 'Password',
    en: 'Password',
  },
  validating: {
    it: 'Validazione in corso',
    en: 'Validating',
  },
  error_validating: {
    it: 'Errore di validazione. Riprova.',
    en: 'Validation error. Retry.',
  },
  check_emails: {
    it: 'Abbiamo ricevuto la tua risposta',
    en: 'We have received your reply',
  },
  check_emails_text: {
    it: 'Ora prenota la tua consulenza gratuita con un nostro esperto, clicca qua sotto per accedere al calendario dal quale puoi scegliere il giorno in cui ricevere una consulenza GRATUITA di 30 min.',
    en: ' Now book your free consultation with one of our experts, click below to access the calendar from which you can choose the day to receive a FREE 30 min  consultation.',
  },
  click_to_book: {
    it: 'CLICCA QUA PER SCEGLIERE GIORNO E ORA',
    en: 'CLICK HERE TO CHOOSE DAY AND TIME'
  },
  gdpr_disclaimer: {
    it:
      'DuckMa rispetta il Regolamento Generale sulla protezione dei dati (GDPR). Inviando il presente form si consente a DuckMa di archiviare e utilizzare internamente il contenuto trasmesso.\nA <a href="https://duckma.com/privacy-policy">questo link</a> puoi consultare la nostra Privacy Policy.',
    en:
      'DuckMa complies with the General Data Protection Regulation (GDPR). By submitting this form you allow DuckMa to store and use the transmitted content internally.\nAt <a href="https://duckma.com/privacy-policy"> this link </a> you can consult our Privacy Policy.',
  },
  gdpr_accept: {
    it: 'Fornisco il consenso al trattamento dei miei dati personali come indicato nella Privacy Policy di cui ho preso visione e di ricevere comunicazioni da DuckMa Srl.',
    en: 'I consent to the processing of my personal data as indicated in the Privacy Policy which I have read and to receive communications from DuckMa Srl.',
  },
  private: {
    it: 'Privato',
    en: 'Private',
  },
  company: {
    it: 'Azienda',
    en: 'Company',
  },
  type: {
    it: 'Tipo',
    en: 'Type',
  },
  tax_code: {
    it: 'Codice fiscale',
    en: 'Tax code',
  },
  vat_code: {
    it: 'Partita IVA',
    en: 'VAT Code',
  },
  complete_profile: {
    it: 'Completa il tuo profilo',
    en: 'Complete your profile',
  },
  first_name: {
    it: 'Nome',
    en: 'name',
  },
  welcome_to: {
    it: 'Benvenuto in',
    en: 'Welcome to',
  },
  duckma_configurator: {
    it: 'Duckma Configurator',
    en: 'Duckma Configurator',
  },
  home_text: {
    it: `Il prezzo mostrato per ogni singolo componente che inserirai nel tuo progetto comprende:
    sviluppo app mobile Android, sviluppo app mobile iOS, console di amministrazione in Cloud, Backend di sistema.
    Salvo se diversamente specificato nell'apposita selezione della piattaforma.
    <br/>
     Ricordati di dare poi un titolo e una dettagliata descrizione del progetto prima di salvarlo.
    <br/>
    Buona navigazione.`,
    en: `The price shown for each single component that you will add to your project includes:
    Android mobile app development, iOS mobile app development, Cloud administration console, System backend.
    Unless otherwise specified in the appropriate platform selection.<br/>
    Remember to add a Title and a detailed description of your project. <br/> Welcome aboard.`,
  },
  start: {
    it: 'Inizia',
    en: 'Start',
  },
  video_tutorial: {
    it: 'Video tutorial',
    en: 'Video tutorial',
  },
  video_tutorial_description: {
    it: 'Video tutorial',
    en: 'Video tutorial',
  },
  book_call: {
    it: 'Prenota una call con l’esperto',
    en: 'Book a call with the expert',
  },
  book_call_description: {
    it: 'Clicca qui per saperne di più',
    en: 'Click here to know more about it',
  },
  configuration_examples: {
    it: 'Esempi di configurazione',
    en: 'Configuration examples',
  },
  configuration_examples_description: {
    it: 'Vuoi avere degli esempi di app sviluppate con Mecha?',
    en: 'Do you want to have examples of apps developed with Mecha?',
  },
  similar_configurator: {
    it: 'Vuoi un configuratore simile a questo?',
    en: 'Do you need a similar configurator?',
  },
  similar_configurator_description: {
    it: 'Clicca qui per saperne di più!',
    en: 'Click here for knowing more!',
  },
  login_cta: {
    it:
      "Per un'esperienza utente migliore e per vedere le tue configurazioni salvate, accedi al configuratore",
    en: 'For a better experience and to access your saved configurations, please login',
  },
}

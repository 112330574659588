import React from 'react'
import styled from 'styled-components'

import { Prospect } from '../../../data/models'
import { Step, stepsOrder } from '../Configurator'
import { TotalPrices } from '../../../hooks/useTotalPrices'
import { ConfiguratorHeader } from '../../../components/ConfiguratorHeader'
import { theme } from '../../../styles/theme'
import { LocalizedLabel } from '../../../components/Typography'
import { ModalContextProvider } from './contexts/ModalContext'
import { priceRounder } from '../../../utils/formatters'
import { LocalizedButton } from '../../../components/Button'

type Props = {
  prospect: Partial<Prospect>
  totalPrices: TotalPrices
  step: Step
  onNext: () => void
  onFinancialPlan?: () => void
  nextError?: string
  onPre?: () => void
  noFooter?: boolean
  nextText?: string
}

export const ConfigurationStep: React.FC<Props> = ({
  prospect,
  totalPrices,
  step,
  onNext,
  onFinancialPlan,
  nextError,
  onPre,
  noFooter,
  nextText,
  children,
}) => {
  return (
    <StepContainer>
      <ModalContextProvider>
        <ConfiguratorHeader
          onBack={onPre}
          perc={(stepsOrder.indexOf(step) + 1) / stepsOrder.length}
        />
        <Content>{children}</Content>
        {!noFooter && (
          <PriceFooter vertical={onFinancialPlan !== undefined}>
            {/*
            <div
              style={{
                flexGrow: 1,
                padding: ['saved', undefined].includes(prospect.status)
                  ? `0 ${onFinancialPlan ? '11' : '4'}vmax`
                  : undefined,
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%',
                visibility:
                  step !== 'summary' && prospect.status === undefined ? 'hidden' : undefined,
              }}
            >
              <Total
                text={[
                  totalPrices.hasCustomizations
                    ? 'capitalized_total_replace_from'
                    : 'capitalized_total_replace',
                  `${priceRounder(totalPrices.oneShotTotal)}`,
                ]}
                color="black"
              />
              {totalPrices.recurrentTotal ? (
                <Total
                  text={[
                    totalPrices.hasCustomizations
                      ? 'capitalized_monthly_total_replace_from'
                      : 'capitalized_monthly_total_replace',
                    `${priceRounder(totalPrices.recurrentTotal)}`,
                  ]}
                  color="black"
                />
              ) : (
                false
              )}
              </div>*/}
            {['saved', undefined].includes(prospect.status) && (
              <ButtonContainer vertical={onFinancialPlan !== undefined}>
                {(prospect.status === 'saved' || prospect.status === undefined) &&
                  onFinancialPlan !== undefined && (
                    <LocalizedButton
                      text="request_financial_plan"
                      size="small"
                      color="gray"
                      onClick={onFinancialPlan}
                      radius={50}
                      tooltip="request_financial_plan"
                      tooltipPosition="top-left"
                      style={{ flexGrow: 0, minWidth: '180px' }}
                    />
                  )}
                {(prospect.status === 'saved' || prospect.status === undefined) && (
                  <LocalizedButton
                    text={nextText || 'next'}
                    color={nextError ? 'primary' : 'danger'}
                    onClick={onNext}
                    size="small"
                    radius={50}
                    tooltip={nextError || nextText || 'next'}
                    tooltipPosition="top-left"
                    disabled={nextError !== undefined}
                    style={{ flexGrow: 0, minWidth: '180px', padding: '16px' }}
                  />
                )}
              </ButtonContainer>
            )}
          </PriceFooter>
        )}
      </ModalContextProvider>
    </StepContainer>
  )
}

const Total = styled(LocalizedLabel)`
  font-size: 1em;
  @media screen and (max-width: 800px) {
    font-size: 0.9em;
  }
`

const Content = styled.div`
  background-color: #fdfdfd;
  width: 100%;
  height: fit-content;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
`

const StepContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-content: center;
  background-color: #fdfdfd;
`
const PriceFooter = styled.div<{ vertical: boolean }>`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 0px;
  left: 0px;
  width: 92vw;
  padding: 3vh 4vw;
  color: ${theme['black']};
  /* background-color: ${theme['primary100']}; */
  color: black;
  background-color: white;
  /* box-shadow: 0px -1px 11px 1px rgba(0,0,0,0.4); */
  box-shadow: 0px -1px 11px 1px rgba(191, 191, 191, 0.39);
  @media print {
    display: none;
  }
  @media screen and (max-width: 800px) {
    ${(props) => (props.vertical ? 'flex-direction: column' : '')}
  }
`

const ButtonContainer = styled.div<{ vertical: boolean }>`
  display: flex;
  flex-grow: 1;
  > button {
    width: 8vmax;
    padding: 10px 4vmax;
  }
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  > :nth-child(2) {
    margin-top: 10px;
  }
  @media screen and (max-width: 800px) {
    > :nth-child(2) {
      margin-top: 10px;
    }
  }
  ${(props) =>
    props.vertical
      ? `
            flex-flow: column nowrap;
            justify-content: space-around; 
            align-items: center;
            > button {
              width: 22vmax;
              padding: 10px 0;
            }
            @media screen and (max-width: 800px) {
              align-items: center; 
              margin-top: 20px;
            }
          `
      : ''}
`

import React, { useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Button, Icon } from '@duckma/react-ds'

import { YoutubeModal } from './YoutubeModal'
import { translate } from '../../i18n/dashboard_labels'
import { InfoModal } from '../configurator/components/InfoModal'
import { useSession } from '../../contexts/SessionContext'
import { Logo } from './components/Logo'
import { IconName } from '@duckma/react-ds/dist/assets/icons/GeneratedIcons'

export const HomePage: React.FC = () => {
  const { currentUser } = useSession()
  const history = useHistory()

  const [isYoutubeModalOpen, setYoutubeModalOpen] = useState(false)
  const [isExamplesModalOpen, setExamplesModalOpen] = useState(false)
  const [isConfiguratorModalOpen, setConfiguratorModalOpen] = useState(false)

  return (
    <>
      {isYoutubeModalOpen && (
        <YoutubeModal
          url="https://www.youtube.com/embed/qxRMn1wjVwY"
          onClose={() => setYoutubeModalOpen(false)}
        />
      )}
      <InfoModal
        url={isExamplesModalOpen ? 'https://l.duckma.com/configuratore' : null}
        onClose={() => setExamplesModalOpen(false)}
      />
      <InfoModal
        url={isConfiguratorModalOpen ? 'https://l.duckma.com/configuratore' : null}
        onClose={() => setConfiguratorModalOpen(false)}
      />
      <Page>
        <PageRow justify="center" marginH="40px" style={{ flexDirection: 'row' }}>
          <LogoArea>
            <Logo />
          </LogoArea>
          <ButtonsArea>
            {currentUser &&
              ((currentUser?.tax_code_or_vat_code?.length ?? 0) > 0 ? (
                <Icon
                  name="user-alt"
                  color="black"
                  size="24px"
                  style={{ padding: '10px', cursor: 'pointer' }}
                  onClick={() => history.push('/me')}
                />
              ) : (
                <div
                  role="tooltip"
                  data-microtip-position="bottom"
                  aria-label={translate('complete_profile')}
                >
                  <svg
                    width="44px"
                    height="44px"
                    viewBox="0 0 44 44"
                    version="1.1"
                    onClick={() => history.push('/me')}
                    style={{ cursor: 'pointer' }}
                  >
                    <g
                      id="redesign-home"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g id="1" transform="translate(-1262.000000, -46.000000)">
                        <g id="Group-2" transform="translate(1262.000000, 46.000000)">
                          <g id="Group" fill="#333333" fill-rule="nonzero">
                            <g
                              id="icon/stroke/user-alt/user-alt-24"
                              transform="translate(10.000000, 10.000000)"
                            >
                              <path
                                d="M12.0516393,10.9 C16.8557204,10.9 20.2063845,13.5348697 21.9353749,18.59823 C22.4886387,20.2184564 21.6236917,21.9804171 20.0034642,22.5336777 C19.6809543,22.6438055 19.3424921,22.7 19.0016978,22.7 L5.02685678,22.7 C3.31478498,22.7 1.92688035,21.3120714 1.92688035,19.6 C1.92688035,19.2479469 1.98684909,18.898466 2.10421359,18.566552 C3.88775998,13.5225724 7.25818561,10.9 12.0516393,10.9 Z M12.0516393,13.1 C8.23341804,13.1 5.66360987,15.0996014 4.17836371,19.2999695 C4.14429047,19.3963307 4.12688035,19.497792 4.12688035,19.6 C4.12688035,20.0970509 4.5298225,20.5 5.02686854,20.5 L19.0016978,20.5 C19.1006383,20.5 19.1989016,20.4836854 19.2925337,20.4517128 C19.7629227,20.2910886 20.0140361,19.7795521 19.8534107,19.3091626 C18.4164211,15.1009285 15.871916,13.1 12.0516393,13.1 Z M12,1.9 C14.2643675,1.9 16.1,3.73563253 16.1,6 C16.1,8.26436747 14.2643675,10.1 12,10.1 C9.73563253,10.1 7.9,8.26436747 7.9,6 C7.9,3.73563253 9.73563253,1.9 12,1.9 Z M12,4.1 C10.950659,4.1 10.1,4.95065898 10.1,6 C10.1,7.04934102 10.950659,7.9 12,7.9 C13.049341,7.9 13.9,7.04934102 13.9,6 C13.9,4.95065898 13.049341,4.1 12,4.1 Z"
                                id="icon-user-alt"
                              ></path>
                            </g>
                          </g>
                          <circle id="Oval" fill="#CC3333" cx="31" cy="31" r="7"></circle>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
              ))}
          </ButtonsArea>
        </PageRow>
        <PageRow justify="center" marginH="30px">
          <TitleArea style={{ textAlign: 'center' }}>
            <div>{translate('welcome_to')}</div>
            <div>{translate('duckma_configurator')}</div>
          </TitleArea>
        </PageRow>
        <PageRow justify="center" marginH="40px">
          <NewArea>
            <SmallTitle>{translate('new_configuration')}</SmallTitle>
            <NewButton
              text={translate('start')}
              iconLeft="add"
              iconRight="angle-right"
              onClick={() => history.push('/configurator')}
            />
            <InfoCard
              icon="phone"
              title="book_call"
              description="book_call_description"
              onClick={() => {
                window.open('https://alt.duckma.com/book-appointment')
              }}
            />
            <InfoCard
              icon="dashboard"
              title="configuration_examples"
              description="configuration_examples_description"
              onClick={() => {
                window.open('https://alt.duckma.com/testimonials')
              }}
            />
            <InfoCard
              icon="file"
              title="similar_configurator"
              description="similar_configurator_description"
              onClick={() => setConfiguratorModalOpen(true)}
            />
          </NewArea>
        </PageRow>
      </Page>
    </>
  )
}

const Page = styled.div`
  font-family: 'Raleway', serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 90vw;
  padding: 0 5vw;
  @media screen and (max-width: 800px) {
    width: 100vw;
    max-width: 100vw;
    padding: 0;
    margin: 0;
  }
`

const LogoArea = styled.div`
  grid-area: logo;
  display: flex;
  align-items: center;
`

const ButtonsArea = styled.div`
  grid-area: buttons;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const TitleArea = styled.div`
  min-width: 480px;
  font-family: 'Raleway', serif;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  > :first-child {
    font-weight: 300;
    font-size: 24px;
    line-height: 48px;
    color: #828894;
  }
  > :last-child {
    font-weight: 800;
    font-size: 36px;
    line-height: 48px;
    color: #828894;
  }
  @media screen and (max-width: 800px) {
    margin: 0 0 0 20px;
    width: calc(100% - 20px);
    min-width: initial;
    justify-content: flex-start;
    > :first-child {
      font-weight: 300;
      font-size: 26px;
      line-height: 32px;
      color: #828894;
    }
    > :last-child {
      font-weight: 900;
      font-size: 26px;
      line-height: 32px;
      color: #828894;
    }
  }
`

const NewArea = styled.div`
  border-radius: 23px;
  background-color: #f4f4f6;
  padding: 40px 40px 20px 40px;
  min-width: 400px;
  @media screen and (max-width: 800px) {
    min-width: calc(100% - 80px);
  }
`

const SmallTitle = styled.span`
  font-family: 'Raleway', serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #222222;
  width: 100%;
  flex-grow: 1;
`

const NewButton = styled(Button)`
  font-family: 'Raleway', serif;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  color: #fff;
  border-radius: 21px;
  padding: 37px;
  display: flex;
  justify-content: left;
  box-shadow: 0 2px 17px 0 rgba(210, 210, 210, 0.41);
  &:nth-child(2) {
    > :nth-child(3) {
      justify-self: flex-end;
      margin-left: auto !important;
    }
  }
  margin-top: 39px;
  margin-bottom: 72px;
  @media screen and (max-width: 800px) {
    margin-top: 24px;
    margin-bottom: 39px;
  }
`

const InfoCard: React.FC<{
  icon: IconName
  title: string
  description: string
  onClick?: () => void
}> = ({ icon, title, description, onClick }) => (
  <StyledInfoCard onClick={onClick}>
    <Icon name={icon} color="black" size="24px" />
    <div>
      <div>{translate(title)}</div>
      <div>{translate(description)}</div>
    </div>
  </StyledInfoCard>
)
const StyledInfoCard = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: nowrap;
  background-color: #fff;
  border-radius: 21px;
  padding: 15px 17px;
  margin-bottom: 20px;
  transition: all 0.2s ease;
  &:hover {
    background-color: #ddd;
    opacity: 0.8;
  }

  div > {
    display: flex;
    flex-flow: column nowrap;
    &:nth-child(1) {
      font-weight: 700;
      font-size: 13px;
      line-height: 18px;
      color: #222;
      margin-left: 15px;
    }

    &:nth-child(2) {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #828894;
      margin-left: 15px;
    }
  }
  box-shadow: 0 2px 17px 0 rgba(210, 210, 210, 0.41);
`

const PageRow = styled.div<{ marginH?: string; justify?: string; align?: string }>`
  width: 100%;
  display: flex;
  align-items: ${(props) => (props.align ? props.align : 'center')};
  margin: ${(props) => (props.marginH ? props.marginH : '30px')} 0;
  ${(props) => (props.justify ? `justify-content: ${props.justify};` : '')}
  @media screen and (max-width: 800px) {
    flex-direction: column;
    margin: 10px 0;
  }
`

import React, { useState, useCallback, useEffect, useRef } from 'react'
import styled from 'styled-components'
// import { GoogleLogin } from 'react-google-login'

import { ControlledField, ControlledForm } from '../../../../components/ControlledForm'
import { Button, Checkbox, Label } from '@duckma/react-ds'
import { api, CreateProspect, RegisterUser } from '../../../../data/api'
import { emailValidator, requiredValidator } from '../../../../utils/validators'
import { LocalizedButton } from '../../../../components/Button'
import { translate } from '../../../../i18n/dashboard_labels'
import { useHistory } from 'react-router-dom'
import { useRequest } from '../../../../hooks/useRequest'
import { Media } from '../../../../data/models'
import { useNetworkQueue } from '../../../../hooks/useQueue'
import { NavigationButton } from '../layouts'
import { ellipse } from '../../../../utils/formatters'
import { LocalizedDescription } from '../../../../components/Typography'

const initialValues = {
  email: '',
  phone_number: '',
  first_name: '',
  last_name: '',
  business_name: '',
  description: '',
}

const toBase64 = async (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })
}

export const ContactPage: React.FC<{ createProspectData: CreateProspect | null }> = ({
  createProspectData,
}) => {
  const history = useHistory()
  const inputRef = useRef<HTMLInputElement>(null)
  const onComplete = useCallback((data: Media) => {
    setValues((v) => ({ ...v, attachments: [...(v.attachments ?? []), data] }))
  }, [])
  const [loadQueue, { enqueue }] = useNetworkQueue(api.createMedia, onComplete)
  const [values, setValues] = useState<
    Omit<RegisterUser, 'provider'> & { description: string; attachments?: Media[] }
  >({
    ...initialValues,
  })
  const [valid, setValid] = useState(false)
  const [flag, setFlag] = useState(false)

  const [registerUser, { status: registerStatus }] = useRequest(api.register)

  const onChange = useCallback((vals, valid) => {
    setValues((v) => ({ ...v, ...vals }))
    setValid(valid)
  }, [])

  useEffect(() => {
    if (registerStatus === 'success') {
      window.onbeforeunload = null
      window.location.href = `https://alt.duckma.com/ty-check-your-email?email=${values.email}&firstname=${values.first_name}&lastname=${values.last_name}`
    }
  }, [history, registerStatus, values])

  return (
    <PageContainer>
      <FormContainer
        onSubmit={(e) => {
          e.preventDefault();
          (e.target as HTMLFormElement).reset()
        }}
      >
        <>
          <LocalizedDescription text="form_prompt"/>
          <ControlledForm initialValues={initialValues} onChange={onChange}>
            <ControlledField
              name="name"
              label="name"
              placeholder="name"
              fieldName="first_name"
              size="large"
              required
              validator={requiredValidator()}
            />
            <ControlledField
              name="last_name"
              label="last_name"
              placeholder="last_name"
              fieldName="last_name"
              size="large"
              required
              validator={requiredValidator()}
            />
            <ControlledField
              name="email"
              fieldName="email"
              size="large"
              label="email"
              placeholder="email"
              required
              validator={emailValidator()}
            />
            <ControlledField
              name="phone_number"
              label="phone_number"
              placeholder="phone_number"
              fieldName="phone_number"
              formatter={(value) => value.replace(/[^0-9\\+\\#]/g, '')}
              required
              size="large"
            />
            <DescriptionControlledField
              rows={5}
              name="short_description_label"
              fieldName="short_description"
              size="large"
              label="short_description_label"
              placeholder="short_description_label"
            />
            {/*<input
            ref={inputRef}
            name="attachment"
            multiple
            type="file"
            placeholder="title_label"
            hidden
            onChange={async (event) => {
              event.preventDefault()
              if (event.target.files && event.target.files.length) {
                for await (const file of event.target.files) {
                  enqueue({
                    base64: await toBase64(file),
                    title: file.name,
                    type: 'doc',
                  })
                }
              }
            }}
          />*/}
          </ControlledForm>
          {/*<NavigationButton
          radius={50}
          outline
          text="add_attachment"
          size="small"
          onClick={() => {
            if (inputRef && inputRef.current) {
              inputRef.current.click()
            }
          }}
          style={{ justifySelf: 'flex-start', marginBottom: '20px' }}
        />
        <div style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'center' }}>
          {values.attachments?.map((a) => (
            <div key={a.id} style={{ position: 'relative', margin: '0 30px' }}>
              <AttachmentButton
                radius={50}
                iconLeft="file"
                tooltip="show_attachment"
                onClick={() => {
                  const link = document.createElement('a')
                  link.href = a.url
                  link.target = '_blank'
                  document.body.appendChild(link)
                  link.click()
                  document.body.removeChild(link)
                }}
              />
              <DeleteAttachmentButton
                size="icon"
                iconLeft="exit"
                color="danger"
                onClick={() => {
                  setValues((v) => ({
                    ...v,
                    attachments: (v.attachments ?? []).filter(
                      (attachment) => attachment.id !== a.id
                    ),
                  }))
                }}
              />
              <Label text={ellipse(a.title)} style={{ position: 'relative', top: '-30px' }} />
            </div>
          ))}
          {loadQueue.map((lm) => (
            <div key={lm.title} style={{ margin: '0 30px' }}>
              <AttachmentButton radius={50} iconLeft="loader" tooltip="loading" />
              <Label text={ellipse(lm.title)} />
            </div>
          ))}
        </div>
          */}
          <span
            dangerouslySetInnerHTML={{ __html: translate('gdpr_disclaimer') }}
            style={{ fontFamily: "'Open Sans', sans-serif", color: '#555555', fontSize: '.8em' }}
          ></span>
          <Checkbox rightText={translate('gdpr_accept')} value={flag} onChange={setFlag} />
          <LocalizedButton
            text="send"
            radius={4}
            style={{ width: '50%', marginLeft: '25%', marginTop: '20px', marginBottom: '30px' }}
            disabled={!valid || !flag}
            loading={registerStatus === 'loading'}
            onClick={() =>
              registerUser({
                ...values,
                provider: 'email',
                ...(createProspectData
                  ? {
                      createProspectData: {
                        ...createProspectData,
                        short_description: values.description,
                        company_name: values.business_name,
                      },
                    }
                  : {}),
              })
            }
          />
        </>
      </FormContainer>
    </PageContainer>
  )
}

const DescriptionControlledField = styled(ControlledField)`
  > textarea {
    line-height: 2;
    padding-top: 16px;
  }
`

const AttachmentButton = styled(NavigationButton)`
  min-width: unset;
  justify-self: 'flex-start';
`
const DeleteAttachmentButton = styled(Button)`
  position: relative;
  top: -80px;
  right: -60px;
  border-radius: 20px;
`

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: calc(100%-64px);
  max-width: 500px;
  padding: 0 32px;
  > * {
    margin: 10px 0;
  }
`

const PageContainer = styled.div`
  background-color: #fdfdfd;
  min-height: 100vh;
  min-width: 100vw;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`
